import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { evRecommendationClick, evRecommendationImpressionWrapper } from 'events/recommendations';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { track } from 'apis/amethyst';
import { buildRecosKey, getProductWithRelatedStyles } from 'helpers/RecoUtils';
import marketplace from 'cfg/marketplace.json';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import ProductCard from 'components/common/ProductCard';
import useFetchProductRelation from 'hooks/useFetchProductRelation';
import { evProductInteract } from 'events/search';
import { formattedProductsWithBadges, getBadgeId, getRowHasBadge } from 'helpers/BadgeUtils';
import { ProductDetailCarousel } from 'components/productdetail/ProductDetailCarousel';

import css from 'styles/components/landing/recommender.scss';

const {
  search: { msaImageParams }
} = marketplace;

export const Recommender = ({ slotName, slotDetails, slotHeartsData }) => {
  const { testId } = useMartyContext();
  const janusRecos = useSelector(state => state.recos);
  const { title, widget } = slotDetails;
  const { janus = {} } = janusRecos;
  const janusEntry = janus[buildRecosKey(slotDetails, widget)];

  const { recos = [], title: p13nTitle } = janusEntry || {};
  const styleIds = recos?.map(reco => reco.styleId) || [];
  const [productRelations] = useFetchProductRelation(styleIds);
  const formattedBadgeProducts = formattedProductsWithBadges(recos, productRelations)?.filter(reco => reco);

  useEffect(() => {
    const recommendationImpression = [
      {
        numberOfRecommendations: formattedBadgeProducts.length,
        recommendationType: 'PRODUCT_RECOMMENDATION',
        recommendationSource: 'EP13N',
        widgetType: 'SIMILAR_PRODUCT_WIDGET'
      }
    ];
    track(() => [evRecommendationImpressionWrapper, { products: formattedBadgeProducts, recommendationImpression }]);
  }, [janusRecos, slotDetails, formattedBadgeProducts.length]);

  if (!janusEntry) {
    return null;
  }

  if (recos.length === 0) {
    return null;
  }

  const rowHasBadge = getRowHasBadge(recos, productRelations);

  const makeRecoCards = () =>
    recos.map((reco, index) => {
      const { styleId, link } = reco;
      let productWithRelatedStyles;

      if (productRelations) {
        productWithRelatedStyles = getProductWithRelatedStyles(styleId, productRelations);
      }

      const { relatedStyles, badges, ...productInfo } = productWithRelatedStyles || {};

      const { productId, colorId, productUrl } = productInfo;
      const url = link || productUrl;

      const recommendedProduct = {
        productId,
        styleId,
        colorId,
        supplementalData: {
          badgeId: getBadgeId(badges)
        }
      };

      const onProductClick = () => {
        track(() => [
          evRecommendationClick,
          {
            index,
            recommendationType: 'PRODUCT_RECOMMENDATION',
            recommendedProduct,
            RecommendationSource: 'EP13N',
            widgetType: 'UNKNOWN_RECOMMENDATION_WIDGET'
          }
        ]);
      };

      if (relatedStyles?.length > 0) {
        const onProductMediaHovered = (reco, styleId) => {
          track(() => [
            evProductInteract,
            {
              mainStyleId: styleId,
              interactedProduct: reco,
              interactionType: 'HOVER'
            }
          ]);
        };

        return (
          <ColorSwatchWrapper
            {...productInfo}
            productUrl={url}
            relatedStyles={relatedStyles}
            heartsInfo={slotHeartsData}
            key={styleId + index}
            eventLabel="Recommender"
            msaImageParams={msaImageParams}
            onProductMediaHovered={onProductMediaHovered}
            onClick={onProductClick}
            testId={testId('recommenderProduct')}
            imageBadgeClassName={rowHasBadge && css.rowHasBadge}
            badges={badges}
          />
        );
      }

      return (
        <ProductCard
          {...reco}
          msaImageParams={msaImageParams}
          eventLabel="Recommender"
          testId={testId('recommenderProduct')}
          onClick={onProductClick}
          key={styleId + index}
          hearts={slotHeartsData}
          imageBadgeClassName={rowHasBadge && css.rowHasBadge}
          badges={badges}
        />
      );
    });

  return (
    <div className={css.carouselContainer} data-slot-id={slotName} data-test-id={testId('recoCarousel')}>
      <ProductDetailCarousel title={title || p13nTitle} slides={makeRecoCards()} dataTestId={'recommenderTitle'} />
    </div>
  );
};

export default withErrorBoundary('Recommender', Recommender);
